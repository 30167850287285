import React from "react";
import { Box } from "@chakra-ui/react";

import Layout from "../components/Layout";
import ContactSection from "../content/ContactSection";

const Contact = (props) => {
  return (
    <Layout>
      <Box paddingTop="100px">
        <ContactSection />
      </Box>
    </Layout>
  );
};

export default Contact;
